import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledSteps = styled.section`
  padding: 20px 0 0 0;
  text-align: center;
  background-color: ${colors.purpleDark};
  color: ${colors.white};

  ${breakpoint.medium`
    padding: 40px 0 20px;
  `}

`	 

	
const StyledContainer = styled(Container)`
  max-width: 1150px;
  padding: 0 0 10px;
  margin: 0 auto;
  
  
  	h3 {
  		font-size: 22px;
		padding-block: 40px 0;
		text-align: center;
		margin-inline: 5px; 

		${breakpoint.small`
  			font-size: 24px;
			padding-block: 40px 10px;
		`}
	}

	.row {
		display: flex;
		column-gap: 10px;
		flex-direction: column;
		width:  100%;
		padding: 10px 20px;

		${breakpoint.small`
			flex-direction: row;
			padding: 20px 30px;
			margin: 0 auto;
		`}
	}
	
	.column {
		flex: 0;
		min-width: 15%;		

		&.title {
			margin-left: 0;
			min-width: 2%;
			
			${breakpoint.medium`
				margin-left: 10px;	
			`}
		}

		&.schedule {
			margin-left: 10px;
			min-width: 90%;
		}
		
		h4 {
			font-size: 20px;
			text-align: center;
			margin-bottom: 10px;
			margin-top: 25px;

			${breakpoint.small`
				font-size: 22px;
				text-align: left;
				margin-top: 0px;
			`}
		}		
		
		p {
			font-size: 15px;
			padding: 0 10px;
			line-height: 1.2rem;
			text-align: center;
			margin-bottom: 1rem;

			${breakpoint.small`
				text-align: left;
				padding: 0;
				line-height: 1.5rem;
				font-size: 16px;
			`}
		}
		
		.firstLine {
			font-weight: 700;
			font-size: 16px;
			
			${breakpoint.small`
				font-size: 18px;
			`}
		}
		
		  a {
			color: ${colors.orange};

			&:hover {
			  color: ${colors.charcoal};
			}
		  }	

	}
	
  `

const Steps = props =>  (
    <StyledSteps>
      <StyledContainer>
      	<h3>Conventional STI testing:</h3>
		<div className="row">
			<div className="column title">
				<h4>1</h4>
			</div>
			<div className="column">
				<p>Schedule an initial consultation with your primary physician.</p>
			</div>
			<div className="column title">
				<h4>2</h4>
			</div>
			<div className="column">
				<p>Get a doctor’s referral from your primary physician.</p>
			</div>
			<div className="column title">
				<h4>3</h4>
			</div>
			<div className="column">
				<p>Schedule an appointment with a sexual health specialist.</p>
			</div>
			<div className="column title">
				<h4>4</h4>
			</div>
			<div className="column">
				<p>Attend specialist consultation, during which, a sample will be taken and sent out to a central lab. Wait at least 48-72 hours.</p>
			</div>
			<div className="column title">
				<h4>5</h4>
			</div>
			<div className="column">
				<p>If positive, return to specialist for treatment recommendation and medical consultation.</p>
			</div>
		</div>
      </StyledContainer>

      <StyledContainer>
      	<h3>The Affordable Rapid model:</h3>
		<div className="row">
			<div className="column title">
				<h4>1</h4>
			</div>
			<div className="column schedule">
				<p className="firstLine">Schedule your appointment and get your testing, results, treatment and consultation in ONE visit</p>
				<p>No more hassle of multiple appointments, no more stress from waiting.</p>
				<p>Affordable Rapid Testing offers same-visit service. Call <a href="tel:+1-480-454-3630">(480) 454-3630</a> to schedule today!</p>
			</div>
		</div>
      </StyledContainer>


    </StyledSteps>
  )

export default Steps
