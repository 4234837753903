import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Header from "../screens/regional-pcr-testing/header"
import Hero from "../screens/regional-pcr-testing/hero"
import Appointment from "../screens/regional-pcr-testing/appointment-01"
import Steps from "../screens/regional-pcr-testing/steps-01"
import Device from "../screens/regional-pcr-testing/sti-device"
import Footer from "../screens/regional-pcr-testing/footer"

const AffordablePage1 = () => {
	return (
		<React.Fragment>
			<Header />
			<Hero />
			<Appointment />
			<Steps />
			<Device />
			<Footer />
		</React.Fragment>
	)
}

export default AffordablePage1

export const Head = () => (
  <Seo
		title="Regional PCR Testing | Affordable Rapid Testing"
		description="Why wait days for STI Results?"
  />
)
