import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

// Icons
import Check from "assets/icons/checkmark-white.svg"

const StyledContainer = styled(Container)`
  max-width: 1150px;
  padding: 0;
  margin: 0 auto;
`

const StyledGetTested = styled.section`
  padding: 20px 0 0 0;
  text-align: center;
  background-color: ${colors.purpleDark};
  color: ${colors.white};

  ${breakpoint.medium`
    padding: 40px 0 140px;
  `}

	h2 {
		text-align: center;
		font-size: 32px;
		padding: 0 5%;
  		color: ${colors.white};

      ${breakpoint.medium`
        font-size: 42px;
		padding: 0;
      `}
	 }
	 
	h4 {
		text-align: center;
  		color: ${colors.white};
		font-size: 21px;  
				
		${breakpoint.small`
			font-size: 23px;  		
		`}
	 }

	h3 {
	 	text-align: center;
		display: inline-grid;
	    margin-left: 0;

		${breakpoint.small`
		    margin-left: 20px;
			display: block;
			text-align: left;
		`}
		
		&::before {
            content: url(${Check});

			${breakpoint.small`
				margin-right: 8px;
			`}
		}
	}


	.detail {
		text-align: center;
		font-size: 16px;
		line-height: 1.4em;
		padding: 0;
		margin-bottom: 1rem !important;

		${breakpoint.small`
			padding: 0 0 0 2rem !important;
			text-align: left;
		`}
	}

	.row {
		display: flex;
		column-gap: 10px;
		margin-top: 0;
		flex-direction: column;
		row-gap: 40px;

		${breakpoint.small`
			flex-direction: row;
		`}
	}
	
	.column {
		flex: 0;
		align-self: center;
		min-width: 30%;

		&.middle {
			flex: 1;
			margin-bottom: 20px;
		}

		&.last {
			margin-bottom: 2.3rem;
		}
		
		h3 {
			margin-bottom: 0.3rem;
		}
		
		p {
			padding: 0 2rem;
			margin: 0 auto 1.8rem !important;
		}
	}
	
	.centerImage {
		width: 100%;
		margin-bottom: 20px;
		padding: 160px;

		${breakpoint.small`
			margin: 30px auto 0px;
			padding: 0 0 0 0;
		`}
	}
	
`

const GetTested = () => {

	return (
		<StyledGetTested>
			<StyledContainer>
			<h2>Get tested regularly for your sexual health</h2>
				<div className="row">
					<div className="column">
						<div data-aos="fade-up">
							<h3>Accurate</h3>
							<p className="detail">Lab-grade PCR results</p>
						</div>
						<div data-aos="fade-up">
							<h3>Fast</h3>
							<p className="detail">Test results in less than 30 minutes, within the span of a clinic visit</p>
						</div>
						<div data-aos="fade-up">
							<h3>Convenient</h3>
							<p className="detail">Accurate and fast test results means proper treatment so you don’t have to come back for additional treatment</p>
						</div>
					</div>
					<div className="column middle">
						<StaticImage
							src="../../../assets/images/regional-pcr-testing/device.png"
							width={440}
							layout="fixed"
							alt="Visby Medical Sexual Health Test"
							className="centerImage"
						/>
						<h4>Visby Medical Sexual<br /> Health Test</h4>
					</div>
					<div className="column last" data-aos="fade-up">
						<p>With regular Visby Medical Sexual Health tests, you can have peace of mind that you’re taking charge of your sexual health.</p>
					</div>
				</div>
			</StyledContainer>
		</StyledGetTested>
	)
}

export default GetTested
